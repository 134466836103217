import type { Id, ToastOptions, UpdateOptions } from 'vue3-toastify'

export function useFormToast(options: ToastOptions = {}) {
  const toastId: Ref<undefined | Id> = ref(undefined)

  const defaultUpdateOptions: UpdateOptions = {
    autoClose: 2500,
    isLoading: false,
    closeButton: true,
    theme: 'light',
    transition: useNuxtApp().$toast.TRANSITIONS.SLIDE,
  }

  const toastIdle = () => {
    if (!toastId.value) return
    useNuxtApp().$toast.remove(toastId.value)
    toastId.value = undefined
  }

  const toastSaving = (message: string = 'Saving') => {
    const $toast = useNuxtApp().$toast
    if (toastId.value && $toast.isActive(toastId.value)) {
      $toast.remove(toastId.value)
    }
    toastId.value = $toast.loading(message, options)
  }

  const toastSuccess = (message: string = 'Success') => {
    if (!toastId.value) return
    useNuxtApp().$toast.update(toastId.value, {
      render: () => {
        return message
      },
      ...defaultUpdateOptions,
      type: 'success',
    })
  }

  const toastError = (message: string = 'Error') => {
    if (!toastId.value) return
    useNuxtApp().$toast.update(toastId.value, {
      render: () => {
        return message
      },
      ...defaultUpdateOptions,
      autoClose: false,
      type: 'error',
    })
  }

  return {
    toastId,
    toastIdle,
    toastSaving,
    toastSuccess,
    toastError,
  }
}
