import { useVuelidate, type ValidationArgs } from '@vuelidate/core'

export interface VuelidateParserField {
  key: string
  value: any
  rules: ValidationArgs
}

export interface VuelidateParserParams extends Array<VuelidateParserField> {}

export function useVuelidateParser(fields: VuelidateParserParams) {
  const rules = ref(
    (() => {
      return Object.fromEntries(
        fields?.map((item) => [item.key, item.rules]) || []
      )
    })()
  )
  const formData = ref(
    (() => {
      return Object.fromEntries(
        fields?.map((item) => [item.key, item.value]) || []
      )
    })()
  )
  const v$ = useVuelidate(rules, formData)

  return {
    v$,
    formData,
    rules,
  }
}
