import { type Validation } from '@vuelidate/core'
import { type VuelidateParserParams } from './useVuelidateParser'
import { useFormToast } from './useFormToast'
import { useAppStore } from '~~/stores/app.store'

export interface FormUtilsParams {
  vuelidate: VuelidateParserParams
  util?: {
    isSubmitting?: boolean
    isLoading?: boolean
  }
  hooks?: {
    onInit?(formData: object, $v: Ref<Validation>): Promise<any>
    onSubmit?(formData: object): Promise<any>
    onSubmitSuccess?(result?: any): void
    onSubmitError?(error?: any): void
  }
}

export function useFormUtils({ vuelidate, util, hooks }: FormUtilsParams) {
  /**
   * Utilities to help with form load and submit controls
   */

  const { v$, formData } = useVuelidateParser(vuelidate)
  const { toastSaving, toastSuccess, toastError } = useFormToast()
  const { appIsBusy } = storeToRefs(useAppStore())

  const isSubmitting = ref(util?.isSubmitting ?? false)
  const isLoading = ref(util?.isLoading ?? false)
  const hasError = ref(false)
  const hasSuccess = ref(false)
  const errorMessage = ref('')
  const successMessage = ref('')

  const resetState = () => {
    isSubmitting.value = false
    isLoading.value = false
    hasError.value = false
    hasSuccess.value = false
    errorMessage.value = ''
    successMessage.value = ''
    appIsBusy.value = false
  }

  const initSubmit = () => {
    resetState()
    isSubmitting.value = true
    appIsBusy.value = true
  }

  onMounted(async () => {
    if (hooks?.onInit) {
      resetState()
      isLoading.value = true
      try {
        formData.value = {
          ...formData.value,
          ...(await hooks.onInit(formData, v$)),
        }
      } finally {
        isLoading.value = false
      }
    }
  })

  const submitForm = async () => {
    if (!canSubmit.value) return
    if (hooks?.onSubmit) {
      initSubmit()
      try {
        toastSaving()
        const result = await hooks.onSubmit(formData.value)
        successMessage.value = 'Data saved successfully.'
        hasSuccess.value = true
        hooks.onSubmitSuccess?.(result)
        toastSuccess(successMessage.value)
      } catch (error) {
        console.error(error)
        errorMessage.value = error?.toString() || 'Error on saving data.'
        hasError.value = true
        hooks.onSubmitError?.(error)
        toastError(errorMessage.value)
      } finally {
        isSubmitting.value = false
        appIsBusy.value = false
      }
    }
  }

  const canSubmit = computed(() => {
    return !v$?.value?.$invalid && !isSubmitting.value && !isLoading.value
  })

  return {
    v$,
    submitForm,
    canSubmit,
    isSubmitting,
    isLoading,
    hasError,
    hasSuccess,
    errorMessage,
    successMessage,
  }
}
